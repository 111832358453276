import { seasonConstants } from "../constants";

const initialState =  { loading: null, seasonList: null, } || {};

export function seasons(state = initialState, action) {
    switch (action.type) {
    case seasonConstants.GETALLSEASONS_REQUEST:
        return {
            loading: true
        };
    case seasonConstants.GETALLSEASONS_SUCCESS:
        return {
            loading: false,
            seasonList: action.seasonList
        };
    case seasonConstants.GETALLSEASONS_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    default:
        return state;
    }
}
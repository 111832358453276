import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Profile from '../../assets/img/profile8.png';
import Menu from "../../assets/img/menu.svg";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { authActions, userAction } from "../../actions";
import { connect } from "react-redux";
import { PAYWHIRL_LINK } from "../../constants/config";


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser')),
        };
        this.handleLogout = this.handleLogout.bind(this);
    }
    getUserInitials(Name) {
        if (Name !== "") {
            const initials = Name.charAt(0);
            return initials.toUpperCase();
        }
        else {
            return "";
        }
    }

    handleLogout(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(authActions.logout());
        // if(user===null){
        // return <Navigate to="/" />
        window.location.href = PAYWHIRL_LINK + "logout";
        window.location.reload();
        // }
    }



    goToPlan() {
        var selectedIframe = document.querySelector(".paywhirl_frame") !== null;
        // console.log(selectedIframe.src);
        if (selectedIframe) {
            var hyperLink = document.querySelector(".paywhirl_frame").src;
            console.log(hyperLink);
            window.open(hyperLink);
        }
        else {
            alert("Widget iframe not found");
        }
        // this.func1();
    }
    func1() {
        window.open(PAYWHIRL_LINK + "logout");

    }


    getUserPlanList() {

        const { dispatch } = this.props;
        dispatch(userAction.userPlan(this.state.user.id));
    }
    componentDidMount() {

        if (this.state.user !== null) {
            this.getUserPlanList();
        }
    }
    render() {
        // const { user } = this.props;
        // if(this.state.currentPlan==="true" || this.state.currentPlan===true)
        // {
        //     return <Navigate to="/nba" />;
        // }

        return (
            <div className="header">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                    <div className="container-fluid px-0">

                        <Button className="bg-transparent border-0 p-0 d-xl-none me-2 d-flex align-items-center" onClick={this.props.handleMobileClick}><img style={{ width: "15px", marginTop: "-1.5px" }} src={Menu} /></Button>
                        {/* <Button className="btn-yellow p-1 d-xl-none me-2" ><img  style={{width:"27px"}} src={Menu} /></Button> */}
                        <a className="navbar-brand text-white me-0 me-lg-3" href="#">NBA Analytics</a>
                        <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <ul className="navbar-nav mb-0 mb-lg-0 d-lg-none">

                                    {
                                        (this.state.user === null)
                                            ? <li className="nav-item">
                                                <Link className="nav-link text-yellow px-0" aria-current="page" to="/login">
                                                    Login
                                                </Link>
                                            </li>
                                            :

                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle text-yellow" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {this.state.user && this.getUserInitials(this.state.user.first_name) + "" + this.getUserInitials(this.state.user.last_name)}
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-end position-absolute" aria-labelledby="navbarDropdown">
                                                    {
                                                        (this.state.currentPlan === "true" || this.state.currentPlan === true)
                                                            ?
                                                            <li>
                                                                {/* <Link class="dropdown-item" to="/subscription-plan">Subscribe Plan</Link> */}
                                                                <a class="dropdown-item" onClick={() => {
                                                                    this.goToPlan();
                                                                }}> Subscribe Plan</a>
                                                            </li>
                                                            :
                                                            <li>
                                                                <Link class="dropdown-item" to="/subscription-plan">Subscribe Plan</Link>

                                                            </li>
                                                    }

                                                    <li><a class="dropdown-item" onClick={this.handleLogout}>Logout</a></li>
                                                </ul>
                                            </li>

                                    }
                                </ul>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="d-flex w-100 py-4 py-lg-0">
                                <form className="d-flex me-auto col-5">
                                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                    <button className="btn" type="submit"><FontAwesomeIcon icon="fas fa-search" /><i className="fas fa-search"></i></button>
                                </form>
                                <ul className="navbar-nav mb-2 mb-lg-0">

                                    {
                                        (this.state.user === null)
                                            ? <li className="nav-item">
                                                <Link className="nav-link text-yellow px-0" aria-current="page" to="/login">
                                                    Login
                                                </Link>
                                            </li>
                                            :

                                            <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle text-yellow" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {this.state.user && this.state.user.first_name + " " + this.state.user.last_name}
                                                </a>
                                                <ul class="dropdown-menu dropdown-menu-end position-absolute" aria-labelledby="navbarDropdown">
                                                    {
                                                        (this.state.currentPlan === "true" || this.state.currentPlan === true)
                                                            ?
                                                            <li>
                                                                {/* <Link class="dropdown-item" to="/subscription-plan">Subscribe Plan</Link> */}
                                                                <a class="dropdown-item" onClick={() => {
                                                                    this.goToPlan();
                                                                }}> Subscribe Plan</a>
                                                            </li>
                                                            :
                                                            <li>
                                                                <Link class="dropdown-item" to="/subscription-plan">Subscribe Plan</Link>

                                                            </li>
                                                    }

                                                    <li><a class="dropdown-item" onClick={this.handleLogout}>Logout</a></li>
                                                </ul>
                                            </li>

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { user, error, loggedIn } = state.rootReducer.authentication;
    return {
        loggedIn,
        user,
        error,
    };
}

export default connect(mapStateToProps)(Header);
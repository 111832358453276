import { teamBoxScoreConstants } from "../constants";

const initialState =  { loadingTeams: null, teamsList: null, } || {};

export function teamBoxScore(state = initialState, action) {
    switch (action.type) {
    case teamBoxScoreConstants.GETALLTEAMSBOXSCORE_REQUEST:
        return {
            loadingTeams: true
        };
    case teamBoxScoreConstants.GETALLTEAMSBOXSCORE_SUCCESS:
        return {
            loadingTeams: false,
            teamsList: action.teamsList
        };
    case teamBoxScoreConstants.GETALLTEAMSBOXSCORE_FAILURE:
        return { 
            error: action.error,
            loadingTeams: false,
        };
    default:
        return state;
    }
}
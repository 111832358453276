import { BoxScoreByDateConstants } from "../../constants";


const boxScoreInitialState = { loadingBoxScore: null, boxScoreByDateList: null } || {};

export function boxScoreByDateReducer(state = boxScoreInitialState, action) {
    switch (action.type) {
        case BoxScoreByDateConstants.GETALLBOXSCOREBYDATE_REQUEST:
            return {
                loadingBoxScore: true
            };
        case BoxScoreByDateConstants.GETALLBOXSCOREBYDATE_SUCCESS:
            return {
                loadingBoxScore: false,
                boxScoreByDateList: action.boxScoreByDateList
            };
        case BoxScoreByDateConstants.GETALLBOXSCOREBYDATE_FAILURE:
            return {
                loadingBoxScore: false,
                error: action.error
            };
        default:
            return state;
    }
}
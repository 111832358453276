import { allTeamsConstants } from "../constants";

const initialState =  { loadingAllTeams: null, allTeamList: null, } || {};

export function allTeamsReducer(state = initialState, action) {
    switch (action.type) {
    case allTeamsConstants.GETALLTEAMS_REQUEST:
        return {
            loadingAllTeams: true
        };
    case allTeamsConstants.GETALLTEAMS_SUCCESS:
        return {
            loadingAllTeams: false,
            allTeamList: action.allTeamList
        };
    case allTeamsConstants.GETALLTEAMS_FAILURE:
        return { 
            error: action.error,
            loadingAllTeams: false,
        };
    default:
        return state;
    }
}
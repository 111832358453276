// export const APIURL = process.env.REACT_APP_APIURL;
// export const KEY = process.env.REACT_APP_KEY;
// export const PROJECTION_KEY = process.env.REACT_APP_PROJECTION_KEY;
// export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// export const PAYWHIRL_LINK = process.env.REACT_APP_PAYWHIRL_LINK;
// export const CUSTOM_DATE_USE_CHECK = process.env.REACT_APP_CUSTOM_DATE_USE;


export const APIURL = "https://api.sportsdata.io/v3/nba/";
export const KEY = "8f27d3676e82471dad14fe2ba49ef2c7";
export const PROJECTION_KEY = "c8db46bcba294f148e31519c5da74c47"
export const CUSTOM_DATE_USE_CHECK = true;
export const CUSTOME_DATE = "06-12-2023";

// export const BACKEND_URL = "http://localhost:3003/";


// FOR DEV SERVER CRIDENTIALS

// export const BACKEND_URL = "https://devapi-sazger.imenso.in/";
// export const PAYWHIRL_LINK = "https://imenso-software.paywhirl.com/";


// FOR CLIENT SERVER CRIDENTIALS
export const BACKEND_URL = "https://api-sazger.imenso.in/";
export const PAYWHIRL_LINK = "https://imenso-software.paywhirl.com/";

// FOR PRODUCTION SERVER CRIDENTIALS
// export const BACKEND_URL = "https://api.fantasyball.io/";
// export const PAYWHIRL_LINK = "https://fantasyball.paywhirl.com/";



import React, { Component } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
// import { Navigate } from "react-router-dom";


export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            mobileShow :false
        }
    }

    // For Mobile Reponsive Start 
    handleClick = () => {
        this.setState({mobileShow: !this.state.mobileShow}) 
    };
    
    handleClickClose = () => {
        this.setState({mobileShow: false}) 
    }
    //  Mobile Reponsive End 

    render() {
        // if(this.state.user === null){
        //     return <Navigate to="/login" />
        // }
        return (
            
            <div>
               <SideBar handleMobileClick={this.handleClickClose} show={this.state.mobileShow}/>
                
                <main className="main basket-bg">
                <Header handleMobileClick={this.handleClick} show={this.state.mobileShow}/>
                    <div className="commingsoon">
                        <h1>Coming Soon</h1>
                    </div>
                </main>
            </div>
            );
    }
}
import React, { Component } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import { Navigate, Link } from "react-router-dom";
import { PAYWHIRL_LINK } from "../../constants/config";
import { authActions, userAction } from "../../actions";
import { connect } from "react-redux";


class SubscriptionPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            // currentPlan: localStorage.getItem('isSubscriberUser'),
            currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser')),
            loadingUsers: true,
        }
    }
    goToPlan() {
        var selectedIframe = document.querySelector(".paywhirl_frame") !== null;
        // console.log(selectedIframe.src);
        if(selectedIframe){
            
            var hyperLink = document.querySelector(".paywhirl_frame").src;
            console.log(hyperLink);
            window.open(hyperLink );
            window.location.href = "/";
        }
        else {
            alert("Widget iframe not found");
        }
        
        // this.func1();
    }
    func1() {
        window.open(PAYWHIRL_LINK + "logout");
    }


    getUserPlanList() {

        const { dispatch } = this.props;
        dispatch(userAction.userPlan(this.state.user.id));
    }
    componentDidMount() {

        // if (this.state.user !== null) {
        //     // console.log('componentDidMonut ',this.state);
        //     if(this.state.currentPlan === false || this.state.currentPlan === null ){
        //         // console.log("plan null ", this.state.currentPlan);
        //         this.getUserPlanList();
        //     }
        // }
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {

        if (nextProps.loadingUsers !== prevProps.loadingUsers) {
            this.setState({
                loadingUsers: nextProps.loadingUsers,
                currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser'))
            }, () => {
                // console.log("hiiiii ", this.state);
                // console.log('josom ',JSON.parse(localStorage.getItem('isSubscriberUser')));
            });
        }
    }
    render() {
        if (this.state.user === null) {
            return <Navigate to="/login" />
        }
        else if (this.state.currentPlan === "true" || this.state.currentPlan === true) {
            return <Navigate to="/nba" />;
        }
        return (
            <div>
                <SideBar></SideBar>
                <main className="main basket-bg">
                    <Header></Header>
                    <div className="commingsoon">
                        <div className="text-center">
                            <button className="btn-yellow me-2" onClick={() => {
                                this.goToPlan();
                            }}> Free Trial</button>

                            {/* <a className="btn-yellow me-2" target="_blank" href="https://app.paywhirl.com/widget/fullscreen/ccb12455-1fe0-4e2c-baac-0a575b902996">Subscribe Plan</a> */}

                            <h4 className="mt-3 text-white">Subscribe for full access to Dashboard.</h4>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

function mapStateToProps(state) {
    // const { loggingIn } = state.rootReducer.authentication;
    const { currentPlan, loadingUsers } = state.rootReducer.users;
    

    return {
        currentPlan,
        loadingUsers
    };
}

export default connect(mapStateToProps)(SubscriptionPlan);
export const userConstants = {
    CREATEUSER_REQUEST: "USER_CREATE_REQUEST",
    CREATEUSER_SUCCESS: "USER_CREATE_SUCCESS",
    CREATEUSER_FAILURE: "USER_CREATE_FAILURE",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
    
    LOGOUT: "USERS_LOGOUT",

    USERPLAN_REQUEST: "USERS_PLAN_REQUEST",
    USERPLAN_SUCCESS: "USERS_PLAN_SUCCESS",
    USERPLAN_FAILURE: "USERS_PLAN_FAILURE",

};

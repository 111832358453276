import { seasonConstants } from "../constants";
import { seasonService } from "../services";

export const seasonActions = {
    getSeasonList
};

function getSeasonList(date) {
    return dispatch => {
        dispatch(request());
        seasonService.getActiveSeasonYear(date)
            .then(
                seasonList => dispatch(success(seasonList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: seasonConstants.GETALLSEASONS_REQUEST }; }
    function success(seasonList) { return { type: seasonConstants.GETALLSEASONS_SUCCESS, seasonList }; }
    function failure(error) { return { type: seasonConstants.GETALLSEASONS_FAILURE, error }; }
}
import { DfsSlateByDateConstants } from "../../constants";

const initialState = { loadingdfsSlate: null, dfsSlateByDateList: null } || {};

export function dfsSlateByDateReducer(state = initialState, action) {
    switch (action.type) {
        case DfsSlateByDateConstants.GETALLDFSSLATEBYDATE_REQUEST:
            return {
                loadingdfsSlate: true
            };
        case DfsSlateByDateConstants.GETALLDFSSLATEBYDATE_SUCCESS:
            return {
                loadingdfsSlate: false,
                dfsSlateByDateList: action.dfsSlateByDateList
            };
        case DfsSlateByDateConstants.GETALLDFSSLATEBYDATE_FAILURE:
            return {
                loadingdfsSlate: false,
                error: action.error
            };
        default:
            return state;
    }
}

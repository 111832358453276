import React, { Component } from "react";
// import Logo from "../../assets/img/logo.png";
import { userAction } from "../../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import CloseButton from '../../assets/img/times-solid.svg'
import HomeHeader from "../Home/homeHeader";


class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stylePath: true,
            customerInfo: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                confirm_password: "",

            },
            successMsg: "",
            error: false,
            userList: [],
            loadingUsers: true,
            modalToggle: false,
        };
        this.handleCustomerInfo = this.handleCustomerInfo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
    }
    modalToggle() {
        this.setState({
            modalToggle: !this.state.modalToggle
        })
    }
    handleCustomerInfo(name, value) {
        console.log('info ', value);
        const { customerInfo } = this.state;
        customerInfo[name] = value;
        this.setState({
            customerInfo
        });
    }

    resetInfo() {
        this.setState({
            customerInfo: {
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                confirm_password: "",
            },
            successMsg: "",
            error: false,
            userList: [],
            loadingUsers: true,
        });
    }

    handleSubmit() {
        this.props.dispatch(userAction.createUser(this.state.customerInfo))
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            // console.log(e.key + '==  Enter');
            this.handleSubmit();
        }
    }
    componentDidMount() {
        // this.setState({modalToggle:true});
        // this.props.dispatch(customerActions.getCustomerList())
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        console.log("nextProps register ", nextProps);

        if (nextProps.loadingUsers !== prevProps.loadingUsers) {
            this.setState({
                loadingUsers: nextProps.loadingUsers
            }, () => {
                // this.props.history.push('/'); 
            });
        }

        if (nextProps.userList !== prevProps.userList) {
            if (nextProps.userList !== undefined && nextProps.userList !== null) {
                this.setState({
                    userList: nextProps.userList,
                    // successMsg: "Your profile has been created successfully, please login the system to view Dashboard.",
                    modalToggle: true,
                }
                // , () => {
                //     setTimeout(() => {
                //         this.resetInfo();
                //         // return <Navigate to="/login" />;
                //         // window.location.href = ""
                //     }, 2200)

                // }
                );
            }
        }

        if (nextProps.error !== "") {
            this.setState({
                error: true
            });
        }
    }
    modalOk() {
        this.setState({
            modalToggle: false,
        }, () => { window.location.href = "/login"; });
    }
    render() {

        const { error } = this.props;
        // if(this.state.successMsg!=""){
        //     return <Navigate to="/login" />
        // }

        const closeBtn = (
            // <button className="close" onClick={() => this.modalToggle()} type="button">
            //     <img width="15" src={CloseButton} />
            // </button>

            <Link className="close" to="/login" type="button">
                <img width="15" src={CloseButton} />
            </Link>
        );
       
        return (

            <div>

                <HomeHeader></HomeHeader>
                <div className="bg-black align-items-center d-flex login singup" id="test21">
                    <div className="container">
                        <div className="col-12 col-sm-8 col-md-8 col-lg-5 m-auto pt-5 mt-4">
                            <div className="pb-5 text-center">
                                {/* <a class="brand-logo" href="/"><img src={Logo} /></a> */}
                                <h1 className="mt-4 mb-2"><span className="text-white"> Register </span></h1>
                            </div>
                            <form className="bg-naviblue">
                                {(this.state.error && error) ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                                {(this.state.successMsg !== "") ? <span className="text-success">{this.state.successMsg}</span> : ""}
                                <div className="row">
                                    <div class="mb-3 col-sm-6">
                                        <label for="first_name" class="form-label text-white">First Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="first_name"
                                            aria-describedby="emailHelp"
                                            value={this.state.customerInfo.first_name}
                                            onChange={(e) => this.handleCustomerInfo('first_name', e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.first_name ? error.first_name : ""}</span> : ""}
                                    </div>
                                    <div class="mb-3 col-sm-6">
                                        <label for="last_name" class="form-label text-white">Last Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="last_name"
                                            aria-describedby="emailHelp"
                                            value={this.state.customerInfo.last_name}
                                            onChange={(e) => this.handleCustomerInfo('last_name', e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.last_name ? error.last_name : ""}</span> : ""}
                                    </div>
                                    <div class="mb-3 col-sm-12">
                                        <label for="email" class="form-label text-white">Email address</label>
                                        <input
                                            type="email"
                                            class="form-control"
                                            id="email"
                                            aria-describedby="emailHelp"
                                            value={this.state.customerInfo.email}
                                            onChange={(e) => this.handleCustomerInfo('email', e.target.value)}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.email ? error.email : ""}</span> : ""}
                                    </div>
                                    <div class="mb-3 col-sm-6">
                                        <label for="password" class="form-label text-white">Password</label>
                                        <input
                                            type="password"
                                            class="form-control"
                                            id="password"
                                            aria-describedby="emailHelp"
                                            value={this.state.customerInfo.password}
                                            onChange={(e) => this.handleCustomerInfo('password', e.target.value)}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.password ? error.password : ""}</span> : ""}
                                    </div>
                                    <div class="mb-3 col-sm-6">
                                        <label for="exampleInputPassword1" class="form-label text-white">Confirm Password</label>
                                        <input
                                            type="password"
                                            class="form-control"
                                            id="exampleInputPassword1"
                                            value={this.state.customerInfo.confirm_password}
                                            onChange={(e) => this.handleCustomerInfo('confirm_password', e.target.value)}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.confirm_password ? error.confirm_password : ""}</span> : ""}
                                    </div>
                                </div>
                                <div className="d-grid pt-4">
                                    <button
                                        type="button"
                                        class="btn-yellow text-black"
                                        onClick={this.handleSubmit}
                                    >Sign Up</button>
                                </div>
                            </form>
                            <p className="mt-3 mb-0 text-center text-white">Already A Member?
                                {/* <a className="text-yellow" href="/login"> Sign in </a> */}
                                <Link className="text-yellow" to="/login"> Sign in</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modalToggle} size="md" centered toggle={() => this.modalToggle()} backdrop="static">
                    <ModalHeader close={closeBtn} className="bg-naviblue text-yellow" toggle={() => this.modalToggle()}>FantasyBall.io</ModalHeader>
                    <ModalBody className="bg-black text-center py-5">
                    Welcome to FantasyBall.io. Your username and password have been created. Log In Now.
                    </ModalBody>
                    <ModalFooter className="bg-naviblue d-flex justify-content-center">
                        <Button onClick={() => this.modalOk()} class="btn-yellow">Ok</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    } 
}


function mapStateToProps(state) {
    // console.log('1111', state);
    const { loadingUsers, userList, error } = state.rootReducer.users;
    // console.log('2222', userList);
    return {
        loadingUsers,
        userList,
        error
    };
}

export default connect(mapStateToProps)(Register);

import { playerListByTeamConstants } from "../constants";

const initialState =  { loading: null, playerList: null, } || {};

export function playerListByTeam(state = initialState, action) {
    switch (action.type) {
    case playerListByTeamConstants.GETALLPLAYERLISTBYTEAM_REQUEST:
        return {
            loading: true
        };
    case playerListByTeamConstants.GETALLPLAYERLISTBYTEAM_SUCCESS:
        return {
            loading: false,
            playerList: action.playerList
        };
    case playerListByTeamConstants.GETALLPLAYERLISTBYTEAM_FAILURE:
        return { 
            error: action.error,
            loading: false,
        };
    default:
        return state;
    }
}
import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.css';
import "../src/assets/css/style.css";
import "../src/assets/fontawesome/css/all.css";
import "../src/assets/fonts/font.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-datepicker/dist/react-datepicker.css";

import {BrowserRouter, Routes, Route} from "react-router-dom";
//import { connect } from "react-redux";
import Home from "./components/Home/Index";

import MaintainanceMode from "./components/Home/maintainanceMode";
import { Provider } from 'react-redux';

import { store } from "./helpers";
// import { alertActions } from "./actions";
import HomePage from "./components/Home/Home"; 
import Glossary from "./components/Home/Glossary";

import MLBPage from "./components/Home/mlbPage";
import NFLPage from "./components/Home/nflPage";

import ComingSoon from "./components/Home/coming-soon";
import SubscriptionPlanPage from "./components/Home/subscription-plan";

import PlayerListPreview from "./components/Home/playerList-preview";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";


export default class App extends Component {

    render() {
        const login =  JSON.parse(localStorage.getItem('user'));
        //const login = localStorage.getItem("isLoggedIn");
        return (
            <Provider store={store}>
                <BrowserRouter>
                    {/*<Router history={history}>*/}
                    <Routes>
                        {/* <Route exact path="/" element={<Home />} />
                        <Route exact path="/home" element={<HomePage />} /> */}

                        <Route exact path="/nba" element={<Home />} />
                        <Route exact path="/" element={<HomePage />} />

                        <Route exact path="/glossary" element={<Glossary />} />
                        <Route exact path="/mlb" element={<MLBPage />} />
                        <Route exact path="/nfl" element={<NFLPage />} />
                        <Route exact path="/coming-soon" element={<ComingSoon />} />
                        <Route exact path="/subscription-plan" element={<SubscriptionPlanPage />} />
                        <Route exact path="/player-list-preview" element={<PlayerListPreview />} />

                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/register" element={<Register />} />
                    </Routes>
                </BrowserRouter>
            </Provider>
        );
    }
}

//export default connect()(App);
import { teamBoxScoreConstants } from "../constants";
import { teamBoxScoreService } from "../services";

export const teamBoxScoreActions = {
    getTeamBoxScore
};

function getTeamBoxScore() {
    return dispatch => {
        dispatch(request());
        teamBoxScoreService.getTeams()
            .then(
                teamsList => dispatch(success(teamsList)),
                error => dispatch(failure(error)) 
            );
    };

    function request() { return { type: teamBoxScoreConstants.GETALLTEAMSBOXSCORE_REQUEST }; }
    function success(teamsList) { return { type: teamBoxScoreConstants.GETALLTEAMSBOXSCORE_SUCCESS, teamsList }; }
    function failure(error) { return { type: teamBoxScoreConstants.GETALLTEAMSBOXSCORE_FAILURE, error }; }
}
import { playerBoxScoreConstants } from "../constants";

const initialState =  { loadingPlayers: null, playersList: null, } || {};

export function playerBoxScore(state = initialState, action) {
    switch (action.type) {
    case playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_REQUEST:
        return {
            loadingPlayers: true
        };
    case playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_SUCCESS:
        return {
            loadingPlayers: false,
            playersList: action.playersList
        };
    case playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_FAILURE:
        return { 
            error: action.error,
            loadingPlayers: false,
        };
    default:
        return state;
    }
}
import { playerBoxScoreConstants } from "../constants";
import { playerBoxScoreService } from "../services";

export const playerBoxScoreActions = {
    getPlayerBoxScore
};

function getPlayerBoxScore(teamName) {
    return dispatch => {
        dispatch(request());

        playerBoxScoreService.getPlayers(teamName)
            .then(
                playersList => dispatch(success(playersList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_REQUEST }; }
    function success(playersList) { return { type: playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_SUCCESS, playersList }; }
    function failure(error) { return { type: playerBoxScoreConstants.GETALLPLAYERSBOXSCORE_FAILURE, error }; }
}
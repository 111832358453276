import React, { Component } from "react";
import Slider from "react-slick";
import Graph  from "../../assets/img/stat-manipulator-graph.png";
import Line1  from "../../assets/img/line-up-1.png";
import Line2  from "../../assets/img/line-up-2.png";
import Filter  from "../../assets/img/filter.png";
 import vdposter  from "../../assets/img/vd-poster.png";
import Balnk  from "../../assets/img/desk_blank.png";
import Video  from "../../assets/img/sazger-ve.MOV";
import PLAYERLIST  from "../../assets/img/playerlist.png";
import PLAYERLIST2  from "../../assets/img/playerlist2.png";
import { Link } from "react-router-dom";
import { authActions, userAction } from "../../actions";
import { connect } from "react-redux";
import { PAYWHIRL_LINK } from "../../constants/config";

import  HomeHeader  from "../Home/homeHeader";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser')),
            loadingUsers: true,
        }
        this.handleLogout = this.handleLogout.bind(this);
    }
    handleLogout(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(authActions.logout());
        // if(user===null){
        // return <Navigate to="/" />
        window.location.href = PAYWHIRL_LINK+"logout";
        setTimeout(() => {
            window.location.reload();
          }, 800)
        
        // }
    }
    getUserPlanList() {

        const { dispatch } = this.props;
        dispatch(userAction.userPlan(this.state.user.id));
    }
    componentDidMount() {

        if (this.state.user !== null) {
            // console.log('componentDidMonut ',this.state);
            if(this.state.currentPlan === false || this.state.currentPlan === null ){
                // console.log("plan null ", this.state.currentPlan);
                this.getUserPlanList();
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {

        if (nextProps.loadingUsers !== prevProps.loadingUsers) {
            this.setState({
                loadingUsers: nextProps.loadingUsers,
                currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser'))
            }, () => {
                // console.log("hiiiii ", this.state);
                // console.log('josom ',JSON.parse(localStorage.getItem('isSubscriberUser')));
            });
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            // speed: 500,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const playyerlist = {
            dots: true,
            infinite: true,
            // speed: 500,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const { user } = this.props;
        console.log("user ", user);

        return (
            <div>
                <HomeHeader></HomeHeader>
                <div className="banner align-items-center d-flex">
                    <div className="container-fluid px-lg-5 px-md-5">
                        <div className="col-12 col-lg-6 col-xl-5 col-md-7">
                            <h1 className="text-yellow mb-1">Advanced Sports Analytics</h1>
                            <h1 className="text-white mb-3">Assisted by Machine Learning-AI</h1>
                            <p className="pera text-white mb-4">Get the Edge on your next Bet or Fantasy move!</p>
                            
                            {/* <button className="btn-try me-2">Try Now - Free</button> */}
                            {
                                this.state.user !== null && (this.state.currentPlan==="true" || this.state.currentPlan===true)
                                ?
                                <Link className="btn-try me-2" to="/nba">Try Now - Free</Link>
                                : this.state.user !== null && (this.state.currentPlan==="false" || this.state.currentPlan===false)
                                ?
                                <Link className="btn-try me-2" to="/subscription-plan">Try Now - Free</Link>
                                :
                                <Link className="btn-try me-2" to="/login">Try Now - Free</Link>
                            }
                            {
                                this.state.user !== null && (this.state.currentPlan==="true" || this.state.currentPlan===true)
                                ?
                                <Link className="btn-member" to="/nba">Member Dashboard</Link>
                                : this.state.user !== null && (this.state.currentPlan==="false" || this.state.currentPlan===false)
                                ?
                                <Link className="btn-member" to="/subscription-plan">Member Dashboard</Link>
                                :
                                <Link className="btn-member" to="/login">Member Dashboard</Link>
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="bg-black">
                    <div className="join-game text-center text-white">
                        <div className="container-fluid px-lg-5 px-md-5">
                            <h2 className="heading mb-3">Join The Game</h2>
                            <p className="paragraph-xs mb-4"> We are the NBA2k of analytics for sports betting and daily fantasy. THE one-stop-shop for everything NBA analytics. </p>
                            <p className="paragraph-sm mb-0">Whether you're a seasoned pro, new to the world of Sports Betting and NBA DFS, or need a hunch on if Lebron will get over 6.5 assists…we have something for everyone. We provide in-depth player statistical analysis, projections in every statistical category, custom slate filters for DFS, the boom or bust feature, and an automated lineup optimizer; we've got you covered. The pros are constantly looking for new tools to get ahead, you should be too.</p>
                        </div>
                    </div>
                    <div className="stat-manipulator text-white">
                        <div className="container-fluid px-lg-5 px-md-5">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-8 col-12 order-1 order-md-0 order-lg-0 order-xl-0">
                                        <img className="inner-img" width="100%" src={Graph} />
                                    </div>
                                    <div className="col-md-4 col-12 d-flex align-items-center">
                                        <div className="">
                                            <h2 className="heading mb-md-5 mb-3">Stat Manipulator</h2>
                                            <p className="paragraph-sm mb-md-0 mb-4">With visually appealing, interactive, and seamless to use technology—our one page dashboard provides an immersive experience with everything you need in one place. Lean on your GM skills to play the game behind the game…</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="machine-learning text-white">
                        <div className="container-fluid px-lg-5 px-md-5">
                            <div className="">
                                <div className="row">
                                    <div className="col-md-4 col-12 d-flex align-items-center">
                                        <div className="">
                                            <h2 className="heading mb-md-5 mb-3">Assisted by Machine Learning - AI</h2>
                                            <p className="paragraph-sm mb-md-0 mb-4">Our machine learning-AI technology is dedicated to providing the latest detailed projected stats (we project and provide trend analysis for over 40 different statistical metrics) to help you make informed decisions and maximize your success. Use this Professional NBA player scout machine to find your edge in NBA DFS and sports betting.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12">
                                        <Slider {...playyerlist}>
                                            <div>
                                                <img className="demo" width="100%" src={PLAYERLIST} />
                                            </div>    
                                            <div>
                                                <img className="demo" width="100%" src={PLAYERLIST2} />
                                            </div>    
                                        </Slider>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="line-up-home text-white">
                        <div className="container-fluid px-lg-5 px-md-5">
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-6 col-md-5 text-center order-1 order-md-0 order-lg-0 order-xl-0 mt-4 mt-md-0">

                                        <Slider {...settings}>
                                            <div>
                                                <img width="" src={Line1} />
                                            </div>
                                            <div>
                                                <img width="" src={Line2} />
                                            </div>
                                        </Slider>
                                        {/* <img width="" src= {Line} /> */}
                                    </div>
                                    <div className="col-lg-6 col-md-7 d-flex align-items-center">
                                        <div className="">
                                            <h2 className="heading">Line Up</h2>
                                            <p className="paragraph-sm my-4 col-lg-8 col-12">We offer a variety of tools and resources to help you build winning lineups, including:</p>
                                            <h5 className="mb-3 pt-2"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>NBA stat projections for 20+ categories</span></h5>
                                            <h5 className="mb-3"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>NBA trend analysis for 30+ statistical categories</span></h5>
                                            <h5 className="mb-3"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>Advanced statistics and metrics trend visualization tool</span></h5>
                                            <h5 className="mb-3"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>Advanced Filterable Player List to organize and discover plays</span></h5>
                                            <h5 className="mb-3"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>Boom or Bust Potential</span></h5>
                                            <h5 className="mb-3"><i class="fas fa-circle text-yellow me-2 fa-xs"></i><span>Automated lineup optimizers for FanDuel, DraftKings, and Yahoo</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="advanced-filters-home text-center py-60">
                        <div className="container">
                            <h2 className="heading mb-5 text-white">Advanced Filters</h2>
                            <div className="">
                                <img className="inner-img" width="600" src={Filter} />
                            </div>
                        </div>
                    </div>
                    <div className="video-hm bg-naviblue text-center py-60 ">
                        <div className="container">
                            <h2 className="heading mb-5 text-white">Demo</h2> 
                            <div className="video">
                                <img src= {Balnk} />
                                <video width="750" height="" controls  poster={vdposter}>
                                    <source src={Video} type="video/mp4"/>
                                </video>
                            </div>
                            <h5 className="mt-5 col-10 m-auto text-white">Why wait? Dominate the Sportsbooks and NBA DFS contests today with our top-notch tools; make a winning bet from our projected statistics and trends. Follow us on social media to stay up-to-date on all things NBA Sports Betting and DFS. Join the ranks of the elite and take your betting and DFS game to the next level with FantasyBall. Happy playing!</h5>
                        </div>
                    </div>
                    <div className="copyright text-center">
                        <h5 className="mb-0 text-white">Copyright©2023 | All Rights Reserved</h5>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    // const { loggingIn } = state.rootReducer.authentication;
    const { user, error, loggedIn } = state.rootReducer.authentication;
    const { currentPlan, loadingUsers } = state.rootReducer.users;
    

    return {
        loggedIn,
        user,
        error,
        currentPlan,
        loadingUsers
    };
}

export default connect(mapStateToProps)(HomePage);
import { APIURL, KEY } from "../constants/config";
import moment from 'moment';

export const seasonService = {
    getActiveSeasonYear,
    getSeasonList
};

async function getActiveSeasonYear(date1) {
    
    // var date = moment().format('DD/MM/YYYY');
    // var dateArr = date.split("/");
    // var seasonYear = dateArr[2]

    // if(dateArr[1] > 10) {

    //     seasonYear = parseInt(dateArr[2]) + 1;
    // }

    // var seasonList = await this.getSeasonList(seasonYear);

    // return seasonList;

    // if(date1==""){
        // var date = moment().subtract(1, 'days').format('DD/MM/YYYY');
    // }
    // else {
        // var date = moment(date1).subtract(1, 'days').format('DD/MM/YYYY');
    // }
    var date = moment(date1).format('DD/MM/YYYY');
    var dateArr = date.split("/");
    var fullDate = dateArr[2]+"-"+dateArr[1]+"-"+dateArr[0];
    var seasonList = await this.getSeasonList(fullDate);
    return seasonList;
}

function getSeasonList(seasonYear) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };

    // return fetch('https://api.sportsdata.io/v3/nba/scores/json/GamesByDate/2022-11-30?key=8f27d3676e82471dad14fe2ba49ef2c7', requestOptions).then(handleResponse)
    return fetch(APIURL+"scores/json/GamesByDate/"+seasonYear+"?key="+KEY, requestOptions).then(handleResponse)
        .then(seasonList => {
            // console.log("My Respo ", seasonList);
            return seasonList;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}
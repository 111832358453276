import React, { Component } from "react";
import SideBar from "./SideBar";

export default class Index extends Component {
    render() {
        return (
            <div>
               <SideBar></SideBar>
               <main className="main p-0">
                    <div className="commingsoon">
                        <h1>Coming Soon</h1>
                    </div>
                </main>
            </div>
            );
    }
}
import React, { Component } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import Teams from "./Teams";
import LeadersGrids from "./LeadersGrids";
import PlayersList from "./PlayersList";
import TopPerformers from "./TopPerformers";
// import PowerScore from "./PowerScore";
// import PlayerCard from "./PlayerCard";
import { Navigate } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import CloseButton from '../../assets/img/times-solid.svg'

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            currentPlan : JSON.parse(localStorage.getItem('isSubscriberUser')), 
            firstTimeLoginUserPlan: JSON.parse(localStorage.getItem('firstTimeLoginUserPlan')),
            modalToggle: false,
            mobileShow :false
        }
        this.modalToggle = this.modalToggle.bind(this);
    }
    modalToggle() {
        this.setState({
            modalToggle: !this.state.modalToggle
        })
    }
    modalOk() {
        this.setState({
            modalToggle: false,
            firstTimeLoginUserPlan: this.state.currentPlan
        }, () => { });
    }

    // For Mobile Reponsive Start 
    handleClick = () => {
        this.setState({mobileShow: !this.state.mobileShow}) 
    };
    
    handleClickClose = () => {
        this.setState({mobileShow: false}) 
    }
    //  Mobile Reponsive End 

    componentDidMount(){
        // this.state.modalToggle =  true
        if(this.state.firstTimeLoginUserPlan == false){
            // this.setState({
            //     modalToggle: false, 
            // }, () => {
            //     localStorage.setItem("firstTimeLoginUserPlan",  this.state.currentPlan);
            // });

            localStorage.setItem("firstTimeLoginUserPlan",  this.state.currentPlan);
        }
        // console.log(this.state);
    }
    render() {
        
        if(this.state.user === null){
            // console.log(423234);
            return <Navigate to="/login" />;
        }
        else {
        }

        const closeBtn = (
            <button className="close" onClick={() => this.modalToggle()} type="button">
                <img width="15" src={CloseButton} />
            </button>
        );
        return (
            <div>
                <Modal isOpen={this.state.modalToggle} size="md" centered toggle={() => this.modalToggle()} backdrop="static">
                    <ModalHeader close={closeBtn} className="bg-naviblue text-yellow" toggle={() => this.modalToggle()}>FantasyBall.io</ModalHeader>
                    <ModalBody className="bg-black text-center py-5">
                    Projections, Boom or Bust, and the Lineup Optimizer will be back after All-Star Break.
                    </ModalBody>
                    <ModalFooter className="bg-naviblue d-flex justify-content-center">
                        <Button onClick={() => this.modalOk()} class="btn-yellow">Ok</Button>
                    </ModalFooter>
                </Modal>
                
               <SideBar handleMobileClick={this.handleClickClose} show={this.state.mobileShow}/>
               {/* <SideBar/> */}
               <main className="main">
                    <Header handleMobileClick={this.handleClick} show={this.state.mobileShow}/>
                    {/* <Header/> */}
                    <div className="wrapar-conaten">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-9">
                                <div className="row">
                                    <div className="col-sm-12"><Teams></Teams></div>
                                    <div className="col-sm-12">
                                        <PlayersList></PlayersList>
                                    </div>
                                </div>    
                            </div>    
                            <div className="col-12 col-md-12 col-lg-3">
                                <TopPerformers></TopPerformers>
                            </div>
                            <div className="col-sm-12">
                                <LeadersGrids></LeadersGrids>
                            </div>
                        </div>
                    </div>    
               </main>
            </div>	
        );
    }
}

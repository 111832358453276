import { regSessionBoxScoreConstants } from "../constants";

const initialState =  { loadingRegSessionList: null, regSessionList: null, } || {};

export function regSessionBoxScore(state = initialState, action) {
    switch (action.type) {
    case regSessionBoxScoreConstants.GETALLREGSESSIONBOXSCORE_REQUEST:
        return {
            loadingRegSessionList: true
        };
    case regSessionBoxScoreConstants.GETALLREGSESSIONBOXSCORE_SUCCESS:
        return {
            loadingRegSessionList: false,
            regSessionList: action.regSessionList
        };
    case regSessionBoxScoreConstants.GETALLREGSESSIONBOXSCORE_FAILURE:
        return { 
            error: action.error,
            loadingRegSessionList: false,
        };
    default:
        return state;
    }
}
import { userConstants } from "../constants";

let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? {loading: null , loggedIn: true, user, error: null, msg: null } : {loading: null , loggedIn: false, user: null, error: null, msg: null };

export function authentication(state = initialState, action) {
    switch (action.type) {
    case userConstants.LOGIN_REQUEST:
        return {
            loggingIn: false,
            user: action.user,
            loading: true,
        };
    case userConstants.LOGIN_SUCCESS:
        return {
            loggedIn: true,
            user: action.user,
            loading: false,
        };
    case userConstants.LOGIN_FAILURE:
        return {
            loggingIn: false,
            error: action.error,
            loading: false,
        };
    case userConstants.LOGOUT:
        return {
            loggedIn: false,
            user: null,
            error: null
        };
    
    default:
        return state;
    }
}
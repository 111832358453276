import React, { Component } from "react";
// import { ReactComponent as Logo } from '../../assets/img/logo-white.svg';
import Logo from "../../assets/img/logo.png";
import Cross from "../../assets/img/times-solid.svg";

import { Button } from 'reactstrap';
import { Link, Navigate } from "react-router-dom";
import { history } from "../../helpers";

export default class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser')),
            stylePath: true,
            clickLink: "home",
            currentPath: "",
            show: false,
        };
        // this.handleClick = this.handleClick.bind(this);
    }

    getCurrentUrlPath() {
        var tt = window.location.pathname;
        var ss = tt.split('/');
        return ss[1];
    }

    
    handleMobileClick = () => {
        this.setState({show: true}) 
      }
    handleClickCancel = () => {
        this.setState({show: true}) 
    }
    componentDidMount() {
        console.log(this.state);
        var currentPage = this.getCurrentUrlPath();
        if (currentPage !== "") {
            this.setState({ clickLink: currentPage });
        }
    }
    render() {

        // const { user} = this.state;
        // console.log(user);
        return (
            <div className={ "sidebar "+(this.props.show ? "show" :"")}>
                
                <Button className="d-xl-none p-0 bg-transparent cancel-btn border-0" onClick={this.props.handleMobileClick}><img style={{width:"16px"}} src={Cross} /> </Button>
                {/* <Button className="d-xl-none p-0 bg-transparent cancel-btn border-0"><img style={{width:"16px"}} src={Cross} /> </Button> */}
                <div className="logo text-center">

                    {
                        this.state.user !== null
                            ?
                            <Link class="navbar-brand inner-img" to="/"><img src={Logo} /></Link>
                            :
                            <Link class="navbar-brand inner-img" to="/login"><img src={Logo} /></Link>
                    }


                    {/* <Logo width="96" height="100"/> */}
                </div>
                <ul className="nav flex-column sidebar-menu">
                    <li className="nav-item">
                        <Link className={`nav-link ${this.state.clickLink === "home" ? "active" : ""}`} name="home" to="/">Home</Link>
                        {/* <a className="nav-link" href="/home">Home</a> */}
                    </li>
                    {
                        this.state.currentPlan === true
                            ?
                            <li className="nav-item">
                                <Link className={`nav-link ${this.state.clickLink === "nba" ? "active" : ""}`} aria-current="page" name="nba" to="/nba">NBA</Link>
                                {/* <a className="nav-link active" aria-current="page" href="/">Stat Manipulator</a> */}
                            </li>
                            :
                            <li className="nav-item">
                                {/* <Link disable className={`nav-link ${this.state.clickLink === "nba" ? "active" : ""}`} aria-current="page" name="nba" to="/nba">NBA</Link> */}
                                <a className={`nav-link ${this.state.clickLink === "nba" ? "active" : ""}`} aria-current="page" href="#">NBA</a>
                            </li>
                    }

                    <li className="nav-item">
                        <Link className={`nav-link ${this.state.clickLink === "mlb" ? "active" : ""}`} name="mlb" to="/mlb">MLB</Link>
                    </li>

                    <li className="nav-item">
                        <Link className={`nav-link ${this.state.clickLink === "nfl" ? "active" : ""}`} name="nfl" to="/nfl">NFL</Link>
                    </li>

                    <li className="nav-item">
                        <Link className={`nav-link ${this.state.clickLink === "glossary" ? "active" : ""}`} name="glossary" to="/glossary">Glossary</Link>
                    </li>

                    {/* {
                        this.state.user !== null
                            ? <li className="nav-item">
                                <Link className={`nav-link ${this.state.clickLink === "subscription_plan" ? "active" : ""}`} name="subscription_plan" to="/subscription-plan">Subscription Plan</Link>
                            </li>
                            : ""
                    } */}


                    {/* <li className="nav-item">
                        <a className="nav-link" href="#">Help & Review</a>
                    </li> */}
                </ul>
            </div>
        );
    }
}

import { playersAdvancedSeasonTotalsConstants } from "../constants";

const initialState =  { loadingPlayersAdvancedSeasonTotals: null, playersAdvancedTotalsList: null, } || {};

export function playersAdvancedSeasonTotals(state = initialState, action) {
    switch (action.type) {
    case playersAdvancedSeasonTotalsConstants.GETALLPLAYERSADVANCEDSEASONTOTALS_REQUEST:
        return {
            loadingPlayersAdvancedSeasonTotals: true
        };
    case playersAdvancedSeasonTotalsConstants.GETALLPLAYERSADVANCEDSEASONTOTALS_SUCCESS:
        return {
            loadingPlayersAdvancedSeasonTotals: false,
            playersAdvancedTotalsList: action.playersAdvancedTotalsList
        };
    case playersAdvancedSeasonTotalsConstants.GETALLPLAYERSADVANCEDSEASONTOTALS_FAILURE:
        return { 
            error: action.error,
            loadingPlayersAdvancedSeasonTotals: false,
        };
    default:
        return state;
    }
}
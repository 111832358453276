import { userConstants } from "../constants";
import { userService } from "../services";
// import { request } from "express";

export const userAction = {
    // getCustomerList,
    createUser,
    userPlan
}

function createUser(userInfo) {
    return dispatch => {
        dispatch(request());

        userService.createUser(userInfo)
        .then(
            userList => dispatch(success(userInfo)),
            error => dispatch(failure(error))
        )
    };

    function request() { return { type: userConstants.CREATEUSER_REQUEST }; }
    function success(userList) { return { type: userConstants.CREATEUSER_SUCCESS, userList }; }
    function failure(error) { return { type: userConstants.CREATEUSER_FAILURE, error }; }
}

function userPlan(customerID) {
    return dispatch => {
        dispatch(request());

        userService.userPlan(customerID)
        .then(
            customerList => dispatch(success(customerID)),
            error => dispatch(failure(error))
        )
    };

    function request() { return { type: userConstants.USERPLAN_REQUEST }; }
    function success(customerList) { return { type: userConstants.USERPLAN_SUCCESS, customerList }; }
    function failure(error) { return { type: userConstants.USERPLAN_FAILURE, error }; }
}

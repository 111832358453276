import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Profile from '../../assets/img/profile8.png';
import { Navigate, Link } from "react-router-dom";
import Logo  from "../../assets/img/logo.png";
import Twitter  from "../../assets/img/twitter.png";
import Insta  from "../../assets/img/insta.png";
import Youtube  from "../../assets/img/youtube.png";
import { authActions } from "../../actions";
import { connect } from "react-redux";
import { PAYWHIRL_LINK } from "../../constants/config";


class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            currentPlan: JSON.parse(localStorage.getItem('isSubscriberUser')),
            // currentPlan: false,
            // userList: [],
        };
        this.handleLogout = this.handleLogout.bind(this);
    }
    getUserInitials(Name) {
        if (Name !== "") {
            const initials = Name.charAt(0);
            return initials.toUpperCase();
        }
        else {
            return "";
        }
    }
    listenScrollEvent = e => {
		if (window.scrollY > 50) {
			this.setState({
				stickyheader: true
			})
		} else {
			this.setState({
				stickyheader: false
			})
		}
	}

    handleLogout(e) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(authActions.logout());
      
        window.location.href = PAYWHIRL_LINK+"logout";
        window.location.reload();
    }
   
    componentDidMount() {


//     const element = document.querySelector(".singup");
// let text = "Height including padding and border: " + element.offsetHeight + "px<br>";
// text += "Width including padding and border: " + element.offsetWidth + "px";
// // document.getElementById("demo").innerHTML = text;
//     console.log(text);
        window.addEventListener('scroll', this.listenScrollEvent)
    }

   
    render() {
        const { user } = this.props;
        return (
                //   <div className="front-header fixed-top">
                 <div className={this.state.stickyheader ? "front-header fixed-top" : "front-header intial"}> 
                    <nav class="navbar navbar-expand navbar-light py-0">
                        <div class="container-fluid px-2 px-lg-5 px-md-5">
                            <Link class="navbar-brand " to="/"><img src={Logo} /></Link>
                            {/* {
                                this.state.user !== null
                                    ?
                                    <Link class="navbar-brand inner-img" to="/nba"><img src={Logo} /></Link>
                                    :
                                    <Link class="navbar-brand inner-img" to="/login"><img src={Logo} /></Link>
                            } */}

                            {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button> */}
                            {/* <div class="collapse navbar-collapse" id="navbarSupportedContent"> */}
                            <div class="navbar-collapse">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li class="nav-item">
                                        <a class="nav-link inner-img" aria-current="page" target="_blank" href="https://www.youtube.com/channel/UCDeaHG12IHye9mPB2GiZhIA"><img className="twitter-icon" src={Youtube} /></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link inner-img" aria-current="page" target="_blank" href="https://twitter.com/fantasyballio"><img className="twitter-icon" src={Twitter} /></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link inner-img" aria-current="page" target="_blank" href="https://www.instagram.com/fantasyball.io/?igshid=Zjc2ZTc4Nzk"><img className="twitter-icon" src={Insta} /></a>
                                    </li>

                                    {
                                        this.state.user !== null
                                            ?
                                            <li class="nav-item">
                                                <Link class="nav-link"> 
                                                    <button className="btn-yellow text-black" onClick={this.handleLogout}>
                                                        Logout
                                                        {/* {this.state.user && this.state.user.first_name + " " + this.state.user.last_name} */}
                                                    </button>
                                                </Link> 
                                            </li>
                                            :
                                            <li class="nav-item">
                                                <Link class="nav-link pe-0" to="/login">
                                                    <button className="btn-yellow text-black">Login</button>
                                                </Link>
                                            </li>
                                    }

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
        );
    }
}

function mapStateToProps(state) {
    const { user, error, loggedIn } = state.rootReducer.authentication;
    return {
        loggedIn,
        user,
        error,
    };
}

export default connect(mapStateToProps)(HomeHeader);
import { APIURL, KEY } from "../constants/config";
// import moment from 'moment';

export const playerBoxScoreService = {
    getPlayers,
    getPlayerBoxScore
};

async function getPlayers(teamName) {
    // var playersList = [];
    // for(var i=1; i<=5; i++) {
    //     var date = moment().subtract(i, 'days').format('DD/MM/YYYY');
    //     var dateArr = date.split("/");
        
    //     var data = await this.getPlayerBoxScore(dateArr[0], dateArr[1], dateArr[2]).then(playerData => {
    //         return playerData
    //     });

    //     playersList = playersList.concat(data);
    // }
    // return playersList;
    var playerList = await this.getPlayerBoxScore(teamName);
    return playerList;
}

async function getPlayerBoxScore(teamName) {
    // const requestOptions = {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" }
    // };
    // return fetch(APIURL+"player_box_scores/"+day+"/"+month+"/"+year, requestOptions).then(handleResponse)
    //     .then(playersList => {
    //         return playersList;
    //     });

    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" }
    };
    // alert(APIURL+"Players/"+teamName+"?key="+KEY);
    return fetch(APIURL+"scores/json/Players/"+teamName+"?key="+KEY, requestOptions).then(handleResponse)
        .then(playerList => {
            // console.log("My Respo ", playerList);
            return playerList;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}
import { userConstants } from "../constants";
import { userService } from "../services";
import { history } from "../helpers";

export const authActions = {
    login,
    logout,
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    // if(user.data.user_role === "reps") {
                        // history.push("/reps/open-booking");
                    // }
                    
                    history.push("/home");
                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user }; }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user }; }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }
}

function logout() {
    userService.logout();
    //     .then(
    //         loggedOut => {
    //             history.push("/");
    //         }
    //     );
    history.push("/");
    return { type: userConstants.LOGOUT };
}

import { userConstants } from "../constants";

const initialState = { loadingUsers: true, userList: null, currentPlan: null } || {};

export function users(state = initialState, action) {
    switch (action.type) {
        
        case userConstants.CREATEUSER_REQUEST:
            return {
                loadingUsers: true,
                userList: state.userInfo
            };
        case userConstants.CREATEUSER_SUCCESS:
            return {
                loadingUsers: false,
                userList: action.userList,
                // redirect:true
            };
        case userConstants.CREATEUSER_FAILURE:
            return {
                error: action.error,
                loadingUsers: false,
                userList: state.userInfo
            };

        
        case userConstants.USERPLAN_REQUEST:
            return {
                loadingUsers: true,
                // currentPlan: state.userInfo
                
            };
        case userConstants.USERPLAN_SUCCESS:
            return {
                loadingUsers: false,
                customerList: action.customerList,
                // redirect:true
            };
        case userConstants.USERPLAN_FAILURE:
            return {
                error: action.error,
                loadingUsers: false,
            };

        default:
            return state;
    }
}
import { BACKEND_URL } from "../constants/config";

export const userService = {
    login,
    logout,
    createUser,
    userPlan
};

function login(email, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password })
    };

    return fetch(BACKEND_URL+"customers/customerLogin", requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log('user11 ',user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("user", JSON.stringify(user.data));
            localStorage.setItem("isSubscriberUser", JSON.stringify(user.currentPlanExist));
            localStorage.setItem("firstTimeLoginUserPlan", JSON.stringify(user.currentPlanExist));
            // localStorage.setItem("isSubscriberUser", JSON.stringify(user.currentPlanExist));
            return user.data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    localStorage.removeItem("isSubscriberUser");
    localStorage.removeItem("firstTimeLoginUserPlan");
    //return loggedOut;
}

async function createUser(userInfo) {
    const requestOption = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userInfo)
    };
    return fetch(BACKEND_URL+"customers/createCustomer", requestOption)
        .then(handleResponse)
        .then(customerList => {
            // console.log('userCreateService', customerList);
            return customerList;
        });
}


async function userPlan(customerID) {
    const requestOption = {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerID })
    };
    return fetch(BACKEND_URL+"customers/getCustomerPlan", requestOption)
        .then(handleResponse)
        .then(customerList => {
            // console.log("BACKEND_URL ", BACKEND_URL );
            // console.log('user-plan-Service', customerList);
            localStorage.setItem("isSubscriberUser", JSON.stringify(customerList.currentPlanExist));
            // localStorage.setItem("fistSubscribePlan", JSON.stringify(customerList.currentPlanExist));
            return customerList.data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        
        const data = text && JSON.parse(text);
        // console.log("data", data);
        if (!response.ok) {
            if (response.status === 400) {
                console.log('response ', data.data.errors);
                // const error = (data && data.data.errors) || response.statusText;
                const error = data.data.errors;
                return Promise.reject(error);
            }
        }
        return data;

    });

}
import React, { Component } from "react";
// import Logo from "../../assets/img/logo.png";
// import Twitter from "../../assets/img/twitter.png";
// import Insta from "../../assets/img/insta.png";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { authActions } from "../../actions";
import HomeHeader from "../Home/homeHeader";


class Login extends Component {
    constructor(props) {
        super(props);
        // Reset Login Status
        // console.log(localStorage.getItem('user'));
        if (localStorage.getItem('user') !== null) {
            this.props.dispatch(authActions.logout());
        }

        this.state = {
            email: "",
            password: "",
            submitted: false,
            error: false,
            loggedIn: false,
            loading: false,
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleEmailChange(e) {
        // e.preventDefault();
        this.setState({ email: e.target.value });
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true }, () =>{ console.log("1 ", this.state);});
        const { email, password } = this.state;
        this.props.dispatch(authActions.login(email, password));
    }

    handleKeyPress (e) {
        if (e.key === 'Enter') {
            // console.log(e.key + '==  Enter');
            this.handleSubmit(e);
        }
    }

    componentDidMount() {
        // console.log(22232);
    }

    UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
        console.log('nextProps ', nextProps);
        if (nextProps.loading !== prevProps.loading) {
            this.setState({
                loading: nextProps.loading
            });
        }
        if (nextProps.error !== "") {
            this.setState({
                error: true,
            });
        }
    }

    render() {
        const { loggedIn, error } = this.props;
        if (loggedIn) {
            console.log("is login");
            if (localStorage.getItem('isSubscriberUser') === "true" || localStorage.getItem('isSubscriberUser') === true) {
                return <Navigate to="/nba" />;
            }
            else {
                return <Navigate to="/subscription-plan" />;
            }

        }

        return (
            <div>
                <HomeHeader></HomeHeader>
                <div className="bg-black align-items-center d-flex login">
                    <div className="container">
                        <div className="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-4 m-auto">
                            <div className="pb-5 text-center">
                                {/* <Link class="brand-logo" to="/"><img src={Logo} /></Link> */}
                                <h1 className="mt-4 mb-2"><span className="text-white"> Login </span></h1>
                            </div>
                            <form className="">
                                <div class="mb-3">
                                    <label for="email" class="form-label text-white">Email address</label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        aria-describedby="emailHelp"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    {this.state.error && error ? <span className="text-danger">{error.email ? error.email : ""}</span> : ""}
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label text-white">Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="exampleInputPassword1"
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    {this.state.error && error ? <span className="text-danger">{error.password ? error.password : ""}</span> : ""}
                                    {this.state.error && error ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                                </div>
                                {/* <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                                        <label class="form-check-label" for="exampleCheck1">Remember Me</label>
                                    </div>
                                    <div className="">
                                        <a href="" className="nav-link">Forgot Password</a>
                                    </div>
                                </div>     */}
                                <div className="d-grid pt-4">
                                    {
                                        !this.state.loading
                                            ?
                                            <button
                                                type="button"
                                                class="btn-yellow text-black"
                                                onClick={this.handleSubmit}
                                                
                                            >Login</button>
                                            :
                                            <button class="btn-yellow" type="button" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                Loading...
                                    </button>
                                    }

                                </div>
                            </form>
                            <p className="mt-3 mb-0 text-center text-white">Don't have a account?
                                {/* <a className="text-yellow" href="/register"> Register </a> */}
                                <Link className="text-yellow ms-1" to="/register">Register</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    // const { loggingIn } = state.rootReducer.authentication;
    const { user, error, loggedIn, loading } = state.rootReducer.authentication;

    return {
        loggedIn,
        user,
        error,
        loading
    };
}

export default connect(mapStateToProps)(Login);
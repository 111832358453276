import { ProjectedPlayerSlatByDateLineUpConstants} from "../../constants";


const initialState = { loadingProjectedPlayerSlatLineUp: null, projectedPlayerSlatByDateListLineUp: null } || {};

export function projectedPlayerSlatByDateLineUpReducer(state = initialState, action) {
    // console.log("Line up Reducer ",action);
    switch (action.type) {
        
        case ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_REQUEST:
            return {
                loadingProjectedPlayerSlatLineUp: true
            };
        case ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_SUCCESS:
            return {
                loadingProjectedPlayerSlatLineUp: false,
                projectedPlayerSlatByDateListLineUp: action.projectedPlayerSlatByDateListLineUp
            };
        case ProjectedPlayerSlatByDateLineUpConstants.GETALLPROJECTEDPLAYERSLATBYDATELINEUP_FAILURE:
            return {
                loadingProjectedPlayerSlatLineUp: false,
                error: action.error

            };
        default:
            return state;
    }
}